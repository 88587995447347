/*
 * @Description: 
 * @Author: 狼牙
 * @Email: huangxiaoming@winhc.cn
 * @Date: 2022-04-18 14:53:50
 * @LastEditTime: 2022-04-18 14:58:44
 * @LastEditors: 狼牙
 */
import React from "react";
import {
  Row,
  Col,
} from "antd";
//获取显示详情模板
export const getDetHtml = (data, value) => {
  let _html = "";
  let sourceContent = value
  if (data.type == '财产线索/利好消息') {
    if (data.collectionName == 'wenshu_detail_v2') {//裁判文书新增恢复执行案件
      _html = <Row className='bgDet'>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={24} className="colType">
            <span
              className='bt'>标题
            </span>
            <span>
              {sourceContent.title ? sourceContent.title : "-"}
            </span>
          </Col>
        </Row>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={8} className="colType">
            <span
              className='bt'>审理法院</span><span>{sourceContent.courtName ? sourceContent.courtName : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>案由</span><span>{sourceContent.caseReason ? sourceContent.caseReason : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>案号</span><span>{sourceContent.caseNo ? sourceContent.caseNo : "-"}</span>
          </Col>
        </Row>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={24} className="colType">
            <span
              className='bt'>案件标的
            </span>
            <span>
              {sourceContent.caseAmt ? (sourceContent.caseAmt + "万元") : "-"}
            </span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>法院名称</span><span>{sourceContent.courtName ? sourceContent.courtName : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>裁判日期</span><span>{sourceContent.judgeDate ? sourceContent.judgeDate : "-"}</span>
          </Col>
        </Row>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={8} className="colType">
            <span
              className='bt'>案件类型</span><span>{sourceContent.caseType ? sourceContent.caseType : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>文书类型</span><span>{sourceContent.docType ? sourceContent.docType : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>案件阶段</span><span>{sourceContent.caseStage ? sourceContent.caseStage : "-"}</span>
          </Col>
        </Row>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={8} className="colType">
            <span
              className='bt'>审判人员</span><span>{sourceContent.judge ? sourceContent.judge : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>被告法人</span><span>{sourceContent.bgFaren ? sourceContent.bgFaren : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>书记员/法官助理</span><span>{sourceContent.clerk ? sourceContent.clerk : "-"}</span>
          </Col>
        </Row>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={8} className="colType">
            <span
              className='bt'>原告名称</span><span>{sourceContent.ygName ? sourceContent.ygName : "-"}</span>
          </Col>
          <Col span={16} className="colType">
            <span
              className='bt'>被告名称</span><span>{sourceContent.bgName ? sourceContent.bgName : "-"}</span>
          </Col>

        </Row>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={24} className="colType">
            <span
              className='bt'>法院观点</span><span>{sourceContent.courtView ? sourceContent.courtView : "-"}</span>
          </Col>

        </Row>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={24} className="colType">
            <span
              className='bt'>审判结果</span><span>{sourceContent.judgeResult ? sourceContent.judgeResult : "-"}</span>
          </Col>

        </Row>
      </Row>
    } else if (data.collectionName == 'company_bid') {//新增招投标
      _html = <Row className='bgDet'>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={24} className="colType">
            <span
              className='bt'>标题
            </span>
            <span>
              {sourceContent.title ? sourceContent.title : "-"}
            </span>
          </Col>
        </Row>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={8} className="colType">
            <span
              className='bt'>采购人</span><span>{sourceContent.purchaser ? sourceContent.purchaser : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>省份地区</span><span>{sourceContent.base ? sourceContent.base : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>标书类型</span><span>{sourceContent.type ? sourceContent.type : "-"}</span>
          </Col>
        </Row>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={8} className="colType">
            <span
              className='bt'>标的类型</span><span>{sourceContent.items ? sourceContent.items : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>代理机构</span><span>{sourceContent.proxy ? sourceContent.proxy : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>更新时间</span><span>{sourceContent.updateTime ? sourceContent.updateTime : "-"}</span>
          </Col>
        </Row>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={24} className="colType">
            <span
              className='bt'>正文简介</span><span>{sourceContent.intro ? sourceContent.intro : "-"}</span>
          </Col>

        </Row>
      </Row>
    } else if (data.collectionName == 'auction_tracking') {//司法拍卖
      _html = <Row className='bgDet' >
        <Row type="flex" justify='start' align='middle' className='row'>

          <Col span={24} className="colType">
            <span
              className='bt'>标题
            </span>
            <span>
              {sourceContent.auctionTitle ? sourceContent.auctionTitle : "-"}
            </span>
          </Col>
        </Row>
        <Row type="flex" justify='start' className='row'>
          <Col span={24} className="colType">
            <span
              className='bt'>详情
            </span>
            <span>
              <a href={sourceContent.url} target='_blank'>点击查看</a>
            </span>
          </Col>

        </Row>
      </Row>
    } else if (data.collectionName == 'company_license') {//行政许可
      _html = <Row className='bgDet'>

        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={8} className="colType">
            <span
              className='bt'>许可文件名称</span><span>{sourceContent.licenseName ? sourceContent.licenseName : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>许可文件编号</span><span>{sourceContent.licenseNumber ? sourceContent.licenseNumber : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>许可机关</span><span>{sourceContent.department ? sourceContent.department : "-"}</span>
          </Col>
        </Row>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={8} className="colType">
            <span
              className='bt'>有效开始日期</span><span>{sourceContent.startDate ? sourceContent.startDate : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>有效结束日期</span><span>{sourceContent.endDate ? sourceContent.endDate : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>更新时间</span><span>{sourceContent.updateTime ? sourceContent.updateTime : "-"}</span>
          </Col>
        </Row>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={24} className="colType">
            <span
              className='bt'>许可内容</span><span>{sourceContent.scope ? sourceContent.scope : "-"}</span>
          </Col>

        </Row>
      </Row>
    } else if (data.collectionName == 'increase_registered_capital_info11') {//工商信息
      _html = <Row className='bgDet'>

        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={8} className="colType">
            <span
              className='bt'>登记状态</span><span>{sourceContent.regStatus ? sourceContent.regStatus : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>成立日期</span><span>{sourceContent.estiblishTime ? sourceContent.estiblishTime : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>注册资本</span><span>{sourceContent.regCapital ? sourceContent.regCapital : "-"}</span>
          </Col>
        </Row>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={8} className="colType">
            <span
              className='bt'>实缴资本</span><span>{sourceContent.actualCapitalAmount ? sourceContent.actualCapitalAmount : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>企业类型</span><span>{sourceContent.companyOrgType ? sourceContent.companyOrgType : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>统一社会信用代码</span><span>{sourceContent.creditCode ? sourceContent.creditCode : "-"}</span>
          </Col>
        </Row>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={8} className="colType">
            <span
              className='bt'>工商注册号</span><span>{sourceContent.regNumber ? sourceContent.regNumber : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>组织机构代码</span><span>{sourceContent.orgNumber ? sourceContent.orgNumber : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>曾用名</span><span>{sourceContent.historyNames ? sourceContent.historyNames.split("\t;\t").join("、") : "-"}</span>
          </Col>
        </Row>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={8} className="colType">
            <span
              className='bt'>经营期限</span><span>
              {sourceContent.fromTime} 至
              {
                sourceContent.toTime
                  ? sourceContent.toTime.substring(0, 10)
                  : sourceContent.fromTime
                    ? "无固定期限"
                    : "-"
              }
            </span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>所属行业</span><span>{sourceContent.cateFirst ? sourceContent.cateFirst : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>注册地址</span><span>{sourceContent.regLocation ? sourceContent.regLocation : "-"}</span>
          </Col>
        </Row>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={8} className="colType">
            <span
              className='bt'>登记机关</span><span>{sourceContent.regInstitute ? sourceContent.regInstitute : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>成立日期</span><span>{sourceContent.estiblishTime ? sourceContent.estiblishTime : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>核准日期</span><span>{sourceContent.approvedTime ? sourceContent.approvedTime : "-"}</span>
          </Col>
        </Row>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={24} className="colType">
            <span
              className='bt'>经营范围</span><span>{sourceContent.businessScope ? sourceContent.businessScope : "-"}</span>
          </Col>

        </Row>
      </Row>
    } else if (data.collectionName == 'increase_registered_capital_info') {//工商信息变更
      _html = <Row className='bgDet' style={{ display: 'contents' }}>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={8} className="colType">
            <span className='bt'>{sourceContent.changeItem}</span>
            <span>{sourceContent.changeItem ? sourceContent.changeItem + '  变更' : "-"}</span>
          </Col>
        </Row>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={8} className="colType">
            <span
              className='bt'>变更时间</span><span>{sourceContent.createTime ? sourceContent.createTime : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>变更前</span><span>{sourceContent.contentBefore ? sourceContent.contentBefore : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>变更后</span><span>{sourceContent.contentAfter ? sourceContent.contentAfter : "-"}</span>
          </Col>
        </Row>

      </Row>
    }

    else if (data.collectionName == 'company_judicial_assistance') {//股权冻结
      _html = <Row className='bgDet'>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={8} className="colType">
            <span
              className='bt'>执行通知文书号</span><span>{sourceContent.executeNoticeNum ? sourceContent.executeNoticeNum : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>被执行人</span><span>{sourceContent.executedPerson ? sourceContent.executedPerson : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>冻结股权标的企业</span><span>{sourceContent.companyName ? sourceContent.companyName : "-"}</span>
          </Col>
        </Row>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={8} className="colType">
            <span
              className='bt'>执行法院</span><span>{sourceContent.executiveCourt ? sourceContent.executiveCourt : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>执行裁定文书号</span><span>{sourceContent.fzExecuteOrderNum ? sourceContent.fzExecuteOrderNum : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>执行通知书文号</span><span>{sourceContent.fzExecuteNoticeNum ? sourceContent.fzExecuteNoticeNum : "-"}</span>
          </Col>
        </Row>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={24} className="colType">
            <span
              className='bt'>被执行人持有股权，其他投资权益的数额</span><span>{sourceContent.fzEquityAmountOther ? sourceContent.fzEquityAmountOther : "-"}</span>
          </Col>

        </Row>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={8} className="colType">
            <span
              className='bt'>被执行人证件种类</span><span>{sourceContent.fzLicenseType ? sourceContent.fzLicenseType : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>被执行人证件号码</span><span>{sourceContent.fzLicenseNum ? sourceContent.fzLicenseNum : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>冻结开始日期</span><span>{sourceContent.fzFromDate ? sourceContent.fzFromDate : "-"}</span>
          </Col>
        </Row>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={8} className="colType">
            <span
              className='bt'>冻结结束日期</span><span>{sourceContent.fzLicenseType ? sourceContent.fzLicenseType : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>冻结期限</span><span>{sourceContent.fzToDate ? sourceContent.fzToDate : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>公示日期</span><span>{sourceContent.fzPublicityDate ? sourceContent.fzPublicityDate : "-"}</span>
          </Col>
        </Row>
      </Row>
    } else if (['zxr_evaluate', 'zxr_evaluate_results'].includes(data.collectionName)) {//询价评估（机构）
      _html = <Row className='bgDet'>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={24} className="colType">
            <span className='bt'>标的物名称</span>
            <span>{sourceContent.assetName ? sourceContent.assetName : "-"}</span>
          </Col>
        </Row>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={8} className="colType">
            <span
              className='bt'>案号
            </span>
            <span>{sourceContent.caseNo ? sourceContent.caseNo : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>法院名称</span>
            <span>{sourceContent.courtName ? sourceContent.courtName : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>当事人</span><span>{sourceContent.name ? sourceContent.name : "-"}</span>
          </Col>
        </Row>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={8} className="colType">
            <span
              className='bt'>财产类型</span><span>{sourceContent.assetName ? sourceContent.assetName : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>摇号日期</span><span>{sourceContent.approTime ? sourceContent.approTime : "-"}</span>
          </Col>
        </Row>
      </Row>
    } else if (data.collectionName == '15') {//移除被执行人信息
      _html = <Row className='bgDet'>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={8} className="colType">
            <span
              className='bt'>案号</span><span>{sourceContent.case_no ? sourceContent.case_no : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>执行法院</span><span>{sourceContent.court ? sourceContent.court : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>执行标的</span><span>{sourceContent.exec_money ? sourceContent.exec_money + "元" : "-"}</span>
          </Col>
        </Row>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={8} className="colType">
            <span
              className='bt'>立案日期</span><span>{sourceContent.case_create_time ? sourceContent.case_create_time : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>身份证号码/组织机构代码</span><span>{sourceContent.proxy ? sourceContent.proxy : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>执行依据文号</span><span>{sourceContent.gist_id ? sourceContent.gist_id : "-"}</span>
          </Col>
        </Row>
      </Row>
    } else if (data.collectionName == 'company_zxr_restrict') {//移除限制高消费
      _html = <Row className='bgDet'>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={8} className="colType">
            <span
              className='bt'>案号</span><span>{sourceContent.caseNo ? sourceContent.caseNo : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>限制消费人名称</span><span>{sourceContent.name ? sourceContent.name : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span className='bt'>性别</span><span>{sourceContent.sex ? sourceContent.sex : "-"}</span>
          </Col>
        </Row>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={8} className="colType">
            <span
              className='bt'>立案日期</span><span>{sourceContent.caseCreateTime ? sourceContent.caseCreateTime : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>执行法院名称</span><span>{sourceContent.courtName ? sourceContent.courtName : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>身份证号码/组织机构代码</span><span>{sourceContent.identityNum ? sourceContent.identityNum : "-"}</span>
          </Col>
        </Row>
      </Row>
    } else if (data.collectionName == 'company_holder') {//新增股东信息
      _html = <Row className='bgDet'>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={8} className="colType">
            <span
              className='bt'>股东名称</span><span>{sourceContent.humanName ? sourceContent.humanName : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>认缴金额</span><span>{sourceContent.amount ? sourceContent.amount + "万元" : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>认缴出资时间</span><span>{sourceContent.capital ? (JSON.parse(sourceContent.capital)[0] ? JSON.parse(sourceContent.capital)[0].time : '暂无') : "-"}</span>
          </Col>
        </Row>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={8} className="colType">
            <span
              className='bt'>认缴出资方式</span><span>{sourceContent.capital ? (JSON.parse(sourceContent.capital)[0] ? (JSON.parse(sourceContent.capital)[0].paymet || '暂无') : '暂无') : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>股东类型</span><span>{!sourceContent.holderType ? "-" : sourceContent.holderType == 1 ? '自然人' : '企业'}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>股东出资比例</span><span>{sourceContent.percent ? (sourceContent.percent * 100 + '%') : "-"}</span>
          </Col>
        </Row>
      </Row>
    } else if (data.collectionName == 'company_dishonest_info') {//移除失信信息
      _html = <Row className='bgDet'>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={8} className="colType">
            <span
              className='bt'>履行情况</span><span>{sourceContent.performance ? sourceContent.performance : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>案号</span><span>{sourceContent.caseNo ? sourceContent.caseNo : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>身份证号码/组织机构代码</span><span>{sourceContent.cardNum ? sourceContent.cardNum : "-"}</span>
          </Col>
        </Row>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={8} className="colType">
            <span
              className='bt'>法人、负责人姓名</span><span>{sourceContent.legalName ? sourceContent.legalName : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>省份地区</span><span>{sourceContent.area ? sourceContent.area : '暂无'}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>法院</span><span>{sourceContent.court ? sourceContent.court : "-"}</span>
          </Col>
        </Row>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={8} className="colType">
            <span
              className='bt'>执行依据文号</span><span>{sourceContent.gistDd ? sourceContent.gistDd : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>立案时间</span><span>{sourceContent.regTime ? sourceContent.regTime : '暂无'}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>做出执行的依据单位</span><span>{sourceContent.gistUnit ? sourceContent.gistUnit : "-"}</span>
          </Col>
        </Row>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={8} className="colType">
            <span
              className='bt'>发布时间</span><span>{sourceContent.pubDate ? sourceContent.pubDate : "-"}</span>
          </Col>
          <Col span={16} className="colType">
            <span
              className='bt'>生效法律文书确定的义务</span><span>{sourceContent.duty ? sourceContent.duty : "-"}</span>
          </Col>
        </Row>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={24} className="colType">
            <span
              className='bt'>失信被执行人行为具体情形</span><span>{sourceContent.actionContent ? sourceContent.actionContent : "-"}</span>
          </Col>
        </Row>
      </Row>
    } else if (data.collectionName == 'company_equity_info') {//新增股权出质
      _html = <Row className='bgDet'>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={24} className="colType">
            <span
              className='bt'>质权人</span><span>{
                sourceContent.pledgeeInfo && JSON.parse(sourceContent.pledgeeInfo).map((e) => {
                  return e.pledgee + " "
                })
              }
            </span>
          </Col>
        </Row>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={24} className="colType">
            <span
              className='bt'>出质人</span><span>{
                sourceContent.pledgorInfo && JSON.parse(sourceContent.pledgorInfo).map((e) => {
                  return e.pledgor + ' '
                })}
            </span>
          </Col>
        </Row>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={8} className="colType">
            <span
              className='bt'>登记编号</span><span>{sourceContent.regNumber ? sourceContent.regNumber : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>证照/证件号码</span><span>{sourceContent.certifNumberPledgor ? sourceContent.certifNumberPledgor : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>出质股权数额</span><span>{sourceContent.equityAmount ? sourceContent.equityAmount : "-"}</span>
          </Col>
        </Row>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={8} className="colType">
            <span
              className='bt'>股权出质设立登记日期</span><span>{sourceContent.regDate ? sourceContent.regDate : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>状态</span><span>{sourceContent.state ? sourceContent.state : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>公示日期</span><span>{sourceContent.pubDate ? sourceContent.pubDate : "-"}</span>
          </Col>
        </Row>
      </Row>
    } else if (data.collectionName == 'company_land_announcement') {//购地信息
      _html = <Row className='bgDet'>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={24} className="colType">
            <span
              className='bt'>项目名称</span><span>{sourceContent.projectName ? sourceContent.projectName : "-"}</span>
          </Col>
        </Row>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={24} className="colType">
            <span
              className='bt'>土地使用权人</span><span>{sourceContent.applicationNam ? sourceContent.applicationNam : "-"}</span>
          </Col>
        </Row>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={8} className="colType">
            <span
              className='bt'>行政区</span><span>{sourceContent.district ? sourceContent.district : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>项目位置</span><span>{sourceContent.projectLoc ? sourceContent.projectLoc : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>面积(公顷)</span><span>{sourceContent.area ? sourceContent.area : "-"}</span>
          </Col>
        </Row>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={8} className="colType">
            <span
              className='bt'>土地用途</span><span>{sourceContent.useType ? sourceContent.useType : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>供地方式</span><span>{sourceContent.supplyMethod ? sourceContent.supplyMethod : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>土地使用年限</span><span>{sourceContent.usePeriod ? sourceContent.usePeriod : "-"}</span>
          </Col>
        </Row>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={8} className="colType">
            <span
              className='bt'>行业分类</span><span>{sourceContent.category ? sourceContent.category : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>土地级别</span><span>{sourceContent.landLevel ? sourceContent.landLevel : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>成交价格(万元)</span><span>{sourceContent.tranPrice ? sourceContent.tranPrice : "-"}</span>
          </Col>
        </Row>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={8} className="colType">
            <span
              className='bt'>约定交地时间</span><span>{sourceContent.commitTime ? sourceContent.commitTime : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>约定开工时间</span><span>{sourceContent.agreementStartTime ? sourceContent.agreementStartTime : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>实际开工时间</span><span>{sourceContent.actualStartTime ? sourceContent.actualStartTime : "-"}</span>
          </Col>
        </Row>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={8} className="colType">
            <span
              className='bt'>约定竣工时间</span><span>{sourceContent.scheduledCompletion ? sourceContent.scheduledCompletion : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>实际竣工时间</span><span>{sourceContent.actualCompletionTime ? sourceContent.actualCompletionTime : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>批准单位</span><span>{sourceContent.authority ? sourceContent.authority : "-"}</span>
          </Col>
        </Row>
      </Row>
    } else if (data.collectionName == 'company_tm') {//知识产权商标
      _html = <Row className='bgDet'>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={8} className="colType">
            <span className='bt'>类型</span><span>{sourceContent.category == 0 ? "普通商标" :
              sourceContent.category == 1 ? "特殊商标" :
                sourceContent.category == 2 ? "集体商标" :
                  sourceContent.category == 3 ? "证明商标" :
                    sourceContent.category == 4 ? "立体商标" :
                      sourceContent.category == 5 ? "声音商标" :
                        "其它"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>商标名</span><span>{sourceContent.tmName ? sourceContent.tmName : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>申请日期</span><span>{sourceContent.appDate ? sourceContent.appDate : "-"}</span>
          </Col>
        </Row>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={8} className="colType">
            <span
              className='bt'>代理人名称</span><span>{sourceContent.agent ? sourceContent.agent : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span className='bt'>商标状态</span><span>{sourceContent.category == 1 ? "有效" :
              sourceContent.category == 2 ? "无效" :
                sourceContent.category == 3 ? "待审" :
                  sourceContent.category == 4 ? "不定" :
                    sourceContent.category == 5 ? "未知状态" :
                      "其它"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>注册号</span><span>{sourceContent.regNo ? sourceContent.regNo : "-"}</span>
          </Col>
        </Row>
      </Row>
    } else if (data.collectionName == 'company_patent') {//知识产权专利
      _html = <Row className='bgDet'>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={24} className="colType">
            <span
              className='bt'>名称</span><span>{sourceContent.title ? sourceContent.title : "-"}</span>
          </Col>

        </Row>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={8} className="colType">
            <span
              className='bt'>申请号</span><span>{sourceContent.appNumber ? sourceContent.apNumber : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>公开公告号</span><span>{sourceContent.pubNumber ? sourceContent.pubNumber : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>申请时间</span><span>{sourceContent.appDate ? sourceContent.appDate : "-"}</span>
          </Col>
        </Row>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={8} className="colType">
            <span
              className='bt'>公开公告日</span><span>{sourceContent.pubDate ? sourceContent.pubDate : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>主分类号</span><span>{sourceContent.mainIpc ? sourceContent.mainIpc : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>申请专利权人</span><span>{sourceContent.applicantName ? JSON.parse(sourceContent.applicantName).join(',') : "-"}</span>
          </Col>
        </Row>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={8} className="colType">
            <span
              className='bt'>发明设计人</span><span>{sourceContent.inventorName ? JSON.parse(sourceContent.inventorName).join(',') : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>专利代理机构</span><span>{sourceContent.agencyName ? sourceContent.agencyName : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>代理人</span><span>{sourceContent.agentName ? JSON.parse(sourceContent.agentName).join(',') : "-"}</span>
          </Col>
        </Row>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={8} className="colType">
            <span
              className='bt'>最新法律状态</span><span>{sourceContent.lprs ? sourceContent.lprs : "-"}</span>
          </Col>
          <Col span={16} className="colType">
            <span
              className='bt'>地址</span><span>{(sourceContent.addrProvince || '-') + '/' + (sourceContent.addrCity || '-') + '/' + (sourceContent.addrCounty || '-') + '/' + (sourceContent.address || '-')}</span>
          </Col>
        </Row>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={24} className="colType">
            <span className='bt'>摘要</span><span>{sourceContent.abs ? sourceContent.abs : "-"}</span>
          </Col>
        </Row>
      </Row>
    } else if (data.collectionName == 'company_copyright_works') {//知识产权作品著作权
      _html = <Row className='bgDet'>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={24} className="colType">
            <span
              className='bt'>作品名称</span><span>{sourceContent.name ? sourceContent.name : "-"}</span>
          </Col>
        </Row>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={8} className="colType">
            <span
              className='bt'>作品类别</span><span>{sourceContent.type ? sourceContent.type : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>著作权人姓名/名称</span><span>{sourceContent.author ? sourceContent.author : "-"}</span>
          </Col>

          <Col span={8} className="colType">
            <span
              className='bt'>登记号</span><span>{sourceContent.regNum ? sourceContent.regNum : "-"}</span>
          </Col>
        </Row>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={8} className="colType">
            <span
              className='bt'>创作完成日期</span><span>{sourceContent.finish_time ? sourceContent.finish_time : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>首次发表日期</span><span>{sourceContent.first_publish_time ? sourceContent.first_publish_time : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>登记日期</span><span>{sourceContent.reg_time ? sourceContent.reg_time : "-"}</span>
          </Col>
        </Row>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={8} className="colType">
            <span
              className='bt'>发布日期</span><span>{sourceContent.publish_time ? sourceContent.publish_time : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>地址</span><span>{(sourceContent.province || "-") + "/" + (sourceContent.city || "-")}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>作者姓名/名称</span><span>{sourceContent.writer ? sourceContent.writer : "-"}</span>
          </Col>
        </Row>
      </Row>
    } else if (data.collectionName == 'company_copyright_reg') {//知识产权软件著作权
      _html = <Row className='bgDet'>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={24} className="colType">
            <span
              className='bt'>软件名称</span><span>{sourceContent.fullName ? sourceContent.fullName : "-"}</span>
          </Col>
        </Row>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={8} className="colType">
            <span
              className='bt'>软件简称</span><span>{sourceContent.simpleName ? sourceContent.simpleName : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>版本号</span><span>{sourceContent.version ? sourceContent.version : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>著作权人</span><span>{sourceContent.authorNationality ? sourceContent.authorNationality : "-"}</span>
          </Col>
        </Row>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={8} className="colType">
            <span
              className='bt'>首次发表时间</span><span>{sourceContent.publishTime ? sourceContent.publishTime : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>登记时间</span><span>{sourceContent.regTime ? sourceContent.regTime : "-"}</span>
          </Col>
        </Row>
      </Row>
    } else if (data.collectionName == 'company_land_mortgage') {//土地转让
      _html = <Row className='bgDet'>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={8} className="colType">
            <span className='bt'>宗地编号</span><span>{sourceContent.landNum ? sourceContent.landNum : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>宗地座落</span><span>{sourceContent.landLoc ? sourceContent.landLoc : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>抵押人</span><span>{sourceContent.mortgagor ? sourceContent.mortgagor : "-"}</span>
          </Col>
        </Row>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={8} className="colType">
            <span
              className='bt'>抵押权人</span><span>{sourceContent.mortgagee ? sourceContent.mortgagee : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>土地面积(公顷)</span><span>{sourceContent.area ? sourceContent.area : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>土地用途</span><span>{sourceContent.useFor ? sourceContent.useFor : "-"}</span>
          </Col>
        </Row>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={8} className="colType">
            <span
              className='bt'>土地使用类型</span><span>{sourceContent.useType ? sourceContent.useType : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>土地编号</span><span>{sourceContent.useRightNum ? sourceContent.useRightNum : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>抵押开始时间</span><span>{sourceContent.startDate ? sourceContent.startDate : "-"}</span>
          </Col>
        </Row>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={8} className="colType">
            <span
              className='bt'>抵押结束时间</span><span>{sourceContent.endDate ? sourceContent.endDate : "-"}</span>
          </Col>

        </Row>

      </Row>
    } else if (data.collectionName == 'company_court_open_announcement') {//开庭公告新增恢复执行案件
      _html = <Row className='bgDet'>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={24} className="colType">
            <span
              className='bt'>上诉人/原告</span><span>{sourceContent.plaintiff ? sourceContent.plaintiff : "-"}</span>
          </Col>
        </Row>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={24} className="colType">
            <span
              className='bt'>被上诉人/被告</span><span >{sourceContent.defendant ? sourceContent.defendant : "-"}</span>
          </Col>
        </Row>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={8} className="colType">
            <span
              className='bt'>案号</span><span>{sourceContent.caseNo ? sourceContent.caseNo : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>案由</span><span>{sourceContent.caseReason ? sourceContent.caseReason : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span className='bt'>区域</span><span>{sourceContent.area ? sourceContent.area : "-"}</span>
          </Col>
        </Row>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={8} className="colType">
            <span
              className='bt'>法院</span><span>{sourceContent.court ? sourceContent.court : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>承办部门</span><span>{sourceContent.department ? sourceContent.department : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>开庭日期(时间)</span><span>{sourceContent.startDate ? sourceContent.startDate : "-"}</span>
          </Col>
        </Row>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={24} className="colType">
            <span
              className='bt'>公告内容</span><span>{sourceContent.content ? sourceContent.content.replace("{", "").replace("}", "") : '暂无'}</span>
          </Col>
        </Row>

      </Row>
    } else if (data.collectionName == 'bankruptcy_open_case') {//破产公告
      _html = <Row className='bgDet'>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={8} className="colType">
            <span
              className='bt'>债权人</span><span>{sourceContent.creditors ? sourceContent.creditors : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>组织机构</span><span>{sourceContent.organization ? sourceContent.organization : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>案号</span><span>{sourceContent.caseNo ? sourceContent.caseNo : "-"}</span>
          </Col>
        </Row>

        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={24} className="colType">
            <span className='bt'>内容</span>
            <span>
              <a style={{ color: "#1890ff", cursor: 'pointer' }} target='_blank' href={sourceContent.linkUrl}>
                查看详情
              </a>
            </span>
          </Col>
        </Row>
      </Row>
    } else if (data.collectionName == 'company_finance') {//融资历史
      _html = <Row className='bgDet'>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={24} className="colType">
            <span
              className='bt'>企业名称</span><span>{sourceContent.companyName ? sourceContent.companyName : "-"}</span>
          </Col>
        </Row>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={24} className="colType">
            <span
              className='bt'>投资机构</span><span>{sourceContent.inverstors ? sourceContent.inverstors : "-"}</span>
          </Col>
        </Row>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={8} className="colType">
            <span
              className='bt'>融资金额</span><span>{sourceContent.money ? sourceContent.money : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>报告日期</span><span>{sourceContent.reportDate ? sourceContent.reportDate : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>更新日期</span><span>{sourceContent.updateTime ? sourceContent.updateTime : "-"}</span>
          </Col>
        </Row>
      </Row>
    } else if (data.collectionName == 'company_mortgage_info') {//动产抵押
      _html = <Row className='bgDet'>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={24} className="colType">
            <span
              className='bt'>企业名称</span><span>{sourceContent.companyName ? sourceContent.companyName : "-"}</span>
          </Col>
        </Row>

        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={8} className="colType">
            <span
              className='bt'>登记编号</span><span>{sourceContent.regNum ? sourceContent.regNum : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>登记日期</span><span>{sourceContent.regDate ? sourceContent.regDate : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>公示时间</span><span>{sourceContent.publishDate ? sourceContent.publishDate : "-"}</span>
          </Col>
        </Row>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={8} className="colType">
            <span
              className='bt'>登记机关</span><span>{sourceContent.regDepartment ? sourceContent.regDepartment : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>数额</span><span>{sourceContent.amount ? sourceContent.amount : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>类型</span><span>{sourceContent.type ? sourceContent.type : "-"}</span>
          </Col>
        </Row>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={24} className="colType">
            <span
              className='bt'>担保范围</span><span>{sourceContent.scope ? sourceContent.scope : "-"}</span>
          </Col>
        </Row>
      </Row>
    } else if (['company_send_announcement', 'company_court_register'].includes(data.collectionName)) {//立案信息,送达公告
      _html = <Row className='bgDet'>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={24} className="colType">
            <span
              className='bt'>上诉人/原告</span><span>{sourceContent.plaintiff ? sourceContent.plaintiff : "-"}</span>
          </Col>
        </Row>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={24} className="colType">
            <span
              className='bt'>被上诉人/被告</span><span>{sourceContent.defendant ? sourceContent.defendant : "-"}</span>
          </Col>
        </Row>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={8} className="colType">
            <span
              className='bt'>案号</span><span>{sourceContent.caseNo ? sourceContent.caseNo : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>案由</span><span>{sourceContent.caseReason ? sourceContent.caseReason : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span className='bt'>区域</span><span>{sourceContent.area ? sourceContent.area : "-"}</span>
          </Col>
        </Row>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={8} className="colType">
            <span
              className='bt'>法院</span><span>{sourceContent.court ? sourceContent.court : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>承办部门</span><span>{sourceContent.department ? sourceContent.department : "-"}</span>
          </Col>
          <Col span={8} className="colType">
            <span
              className='bt'>开庭日期(时间)</span><span>{sourceContent.startDate ? sourceContent.startDate : "-"}</span>
          </Col>
        </Row>
        <Row type="flex" justify='start' align='middle' className='row'>
          <Col span={24} className="colType">
            <span
              className='bt'>公告内容</span><span>{sourceContent.content ? sourceContent.content.replace("{", "").replace("}", "") : '暂无'}</span>
          </Col>
        </Row>

      </Row>
    }

  } else if (data.type == '法院公告') {//被告新增作为原告法院公告
    _html = <Row className='bgDet'>
      <Row type="flex" justify='start' align='middle' className='row'>
        <Col span={24} className="colType">
          <span
            className='bt'>原告</span><span>{sourceContent.plaintiff ? sourceContent.plaintiff : "-"}</span>
        </Col>
      </Row>
      <Row type="flex" justify='start' align='middle' className='row'>
        <Col span={24} className="colType">
          <span
            className='bt'>当事人</span><span>{sourceContent.litigant ? sourceContent.litigant : "-"}</span>
        </Col>
      </Row>

      <Row type="flex" justify='start' align='middle' className='row'>
        <Col span={8} className="colType">
          <span
            className='bt'>法院名称</span><span>{sourceContent.courtName ? sourceContent.courtName : "-"}</span>
        </Col>
        <Col span={8} className="colType">
          <span
            className='bt'>公告类型名称</span><span>{sourceContent.announcementType ? sourceContent.announcementType : "-"}</span>
        </Col>
        <Col span={8} className="colType">
          <span
            className='bt'>刊登日期</span><span>{sourceContent.publishDate ? sourceContent.publishDate : "-"}</span>
        </Col>
      </Row>
      <Row type="flex" justify='start' align='middle' className='row'>
        <Col span={24} className="colType">
          <span
            className='bt'>案件内容</span><span>{sourceContent.content ? sourceContent.content : "-"}</span>
        </Col>

      </Row>
    </Row>
  } else if (['2'].includes(data.type)) {//被告公司注销
    _html = <Row className='bgDet'>
      <Row type="flex" justify='start' align='middle' className='row'>
        <Col span={8} className="colType">
          <span className='bt'>企业状态</span><span
            style={{ color: 'rgb(194, 35, 35)' }}>{sourceContent.regStatus ? sourceContent.regStatus : "-"}</span>
        </Col>
        <Col span={8} className="colType">
          <span
            className='bt'>法人名称</span><span>{sourceContent.legalEntityName ? sourceContent.legalEntityName : "-"}</span>
        </Col>
        <Col span={8} className="colType">
          <span
            className='bt'>法人类型</span><span>{sourceContent.legalEntityType == '1' ? "人" : "公司"}</span>
        </Col>
      </Row>
      <Row type="flex" justify='start' align='middle' className='row'>
        <Col span={8} className="colType">
          <span className='bt'>公司名称</span><span>{sourceContent.name ? sourceContent.name : "-"}</span>
        </Col>
        <Col span={8} className="colType">
          <span
            className='bt'>注册号</span><span>{sourceContent.regNumber ? sourceContent.regNumber : "-"}</span>
        </Col>
        <Col span={8} className="colType">
          <span
            className='bt'>公司类型</span><span>{sourceContent.companyOrgType ? sourceContent.companyOrgType : "-"}</span>
        </Col>
      </Row>
      <Row type="flex" justify='start' align='middle' className='row'>
        <Col span={8} className="colType">
          <span
            className='bt'>注册地址</span><span>{sourceContent.regLocation ? sourceContent.regLocation : "-"}</span>
        </Col>
        <Col span={8} className="colType">
          <span
            className='bt'>成立日期</span><span>{sourceContent.estiblishTime ? sourceContent.estiblishTime : "-"}</span>
        </Col>
        <Col span={8} className="colType">
          <span
            className='bt'>经营范围</span><span>{sourceContent.businessScope ? sourceContent.businessScope : "-"}</span>
        </Col>
      </Row>
      <Row type="flex" justify='start' align='middle' className='row'>
        <Col span={8} className="colType">
          <span
            className='bt'>登记机关</span><span>{sourceContent.regInstitute ? sourceContent.regInstitute : "-"}</span>
        </Col>
        <Col span={8} className="colType">
          <span
            className='bt'>核准日期</span><span>{sourceContent.approvedTime ? sourceContent.approvedTime : "-"}</span>
        </Col>
        <Col span={8} className="colType">
          <span
            className='bt'>注册资本</span><span>{sourceContent.regCapital ? sourceContent.regCapital : "-"}</span>
        </Col>
      </Row>
      <Row type="flex" justify='start' align='middle' className='row'>
        <Col span={8} className="colType">
          <span
            className='bt'>组织机构代码</span><span>{sourceContent.orgNumber ? sourceContent.orgNumber : "-"}</span>
        </Col>
        <Col span={8} className="colType">
          <span className='bt'>机构类型</span><span>{sourceContent.companyType == 1 ? "公司" :
            sourceContent.companyType == 2 ? "香港企业" :
              sourceContent.companyType == 3 ? "社会组织" :
                sourceContent.companyType == 4 ? "律所" :
                  sourceContent.companyType == 5 ? "事业单位" :
                    sourceContent.companyType == 6 ? ":基金会" :
                      sourceContent.companyType == 7 ? ":台湾企业" :
                        "其它"}</span>
        </Col>
        <Col span={8} className="colType">
          <span
            className='bt'>统一社会信用代码</span><span>{sourceContent.creditCode ? sourceContent.creditCode : "-"}</span>
        </Col>
      </Row>
      <Row type="flex" justify='start' align='middle' className='row'>
        <Col span={8} className="colType">
          <span className='bt'>公司评分</span><span>{sourceContent.score ? sourceContent.score : "-"}</span>
        </Col>
        <Col span={8} className="colType">
          <span
            className='bt'>注销日期</span><span>{sourceContent.cancelDate ? sourceContent.cancelDate : "-"}</span>
        </Col>
        <Col span={8} className="colType">
          <span
            className='bt'>吊销日期</span><span>{sourceContent.revokeDate ? sourceContent.revokeDate : "-"}</span>
        </Col>
      </Row>
    </Row>
  } else if (data.type == '一审胜诉文书') {//被告新增一审作为原告胜诉文书 原告新增一审胜诉文书
    _html = <Row className='bgDet'>
      <Row type="flex" justify='start' align='middle' className='row'>
        <Col span={8} className="colType">
          <span
            className='bt'>标的额</span><span>{sourceContent.caseAmt ? sourceContent.caseAmt : "-"}</span>
        </Col>
        <Col span={8} className="colType">
          <span
            className='bt'>案号</span><span>{sourceContent.caseNo ? sourceContent.caseNo : "-"}</span>
        </Col>
        <Col span={8} className="colType">
          <span
            className='bt'>案由</span><span>{sourceContent.caseReason ? sourceContent.caseReason : "-"}</span>
        </Col>
      </Row>
      <Row type="flex" justify='start' align='middle' className='row'>
        <Col span={8} className="colType">
          <span
            className='bt'>判决日期</span><span>{sourceContent.judgeDate ? sourceContent.judgeDate : "-"}</span>
        </Col>
        <Col span={8} className="colType">
          <span
            className='bt'>审理法院</span><span>{sourceContent.courtName ? sourceContent.courtName : "-"}</span>
        </Col>
        <Col span={8} className="colType">
          <span
            className='bt'>联系手机号</span><span>{sourceContent.mobileNo ? sourceContent.mobileNo : "-"}</span>
        </Col>
      </Row>

      <Row type="flex" justify='start' align='middle' className='row'>
        <Col span={24} className="colType">
          <span
            className='bt'>裁判结果</span><span>{sourceContent.judgeResult ? sourceContent.judgeResult : "-"}</span>
        </Col>

      </Row>
    </Row>
  } else if (data.type == '终本案件') {//原告新增终本案件
    _html = <Row className='bgDet'>
      <Row type="flex" justify='start' align='middle' className='row'>
        <Col span={8} className="colType">
          <span
            className='bt'>案件阶段</span><span>{sourceContent.caseStage ? sourceContent.caseStage : "-"}</span>
        </Col>
        <Col span={8} className="colType">
          <span
            className='bt'>案号</span><span>{sourceContent.caseNo ? sourceContent.caseNo : "-"}</span>
        </Col>
        <Col span={8} className="colType">
          <span
            className='bt'>案由</span><span>{sourceContent.caseReason ? sourceContent.caseReason : "-"}</span>
        </Col>
      </Row>
      <Row type="flex" justify='start' align='middle' className='row'>
        <Col span={8} className="colType">
          <span
            className='bt'>判决日期</span><span>{sourceContent.judgeDate ? sourceContent.judgeDate : "-"}</span>
        </Col>
        <Col span={8} className="colType">
          <span
            className='bt'>执行法院</span><span>{sourceContent.courtName ? sourceContent.courtName : "-"}</span>
        </Col>
        <Col span={8} className="colType">
          <span
            className='bt'>联系手机号</span><span>{sourceContent.mobileNo ? sourceContent.mobileNo : "-"}</span>
        </Col>
      </Row>
      <Row type="flex" justify='start' align='middle' className='row'>
        <Col span={8} className="colType">
          <span
            className='bt'>注册资本</span><span>{sourceContent.regCapital ? sourceContent.regCapital : "-"}</span>
        </Col>
        <Col span={8} className="colType">
          <span
            className='bt'>成立日期</span><span>{sourceContent.estiblishTime ? sourceContent.estiblishTime : "-"}</span>
        </Col>
        <Col span={8} className="colType">
          <span
            className='bt'>身份证号</span><span>{sourceContent.identityNum ? sourceContent.identityNum : "-"}</span>
        </Col>
      </Row>
    </Row>
  } else if (['开庭公告', '立案信息', '送达公告'].includes(data.type)) {//开庭公告新增恢复执行案件
    _html = <Row className='bgDet'>
      <Row type="flex" justify='start' align='middle' className='row'>
        <Col span={24} className="colType">
          <span
            className='bt'>上诉人/原告</span><span>{sourceContent.plaintiff ? sourceContent.plaintiff : "-"}</span>
        </Col>
      </Row>
      <Row type="flex" justify='start' align='middle' className='row'>
        <Col span={24} className="colType">
          <span
            className='bt'>被上诉人/被告</span><span>{sourceContent.defendant ? sourceContent.defendant : "-"}</span>
        </Col>
      </Row>
      <Row type="flex" justify='start' align='middle' className='row'>
        <Col span={8} className="colType">
          <span
            className='bt'>案号</span><span>{sourceContent.caseNo ? sourceContent.caseNo : "-"}</span>
        </Col>
        <Col span={8} className="colType">
          <span
            className='bt'>案由</span><span>{sourceContent.caseReason ? sourceContent.caseReason : "-"}</span>
        </Col>
        <Col span={8} className="colType">
          <span className='bt'>区域</span><span>{sourceContent.area ? sourceContent.area : "-"}</span>
        </Col>
      </Row>
      <Row type="flex" justify='start' align='middle' className='row'>
        <Col span={8} className="colType">
          <span
            className='bt'>法院</span><span>{sourceContent.court ? sourceContent.court : "-"}</span>
        </Col>
        <Col span={8} className="colType">
          <span
            className='bt'>承办部门</span><span>{sourceContent.department ? sourceContent.department : "-"}</span>
        </Col>
        <Col span={8} className="colType">
          <span
            className='bt'>开庭日期(时间)</span><span>{sourceContent.startDate ? sourceContent.startDate : "-"}</span>
        </Col>
      </Row>
      <Row type="flex" justify='start' align='middle' className='row'>
        <Col span={24} className="colType">
          <span
            className='bt'>公告内容</span><span>{sourceContent.content ? sourceContent.content.replace("{", "").replace("}", "") : '暂无'}</span>
        </Col>
      </Row>

    </Row>
  }
  return _html


}