import React from 'react'
import { Form, Input, Button, message, Card, Modal } from 'antd'
import { Axios } from '../../axios'
import './login.less'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { change_operInfo, change_operAuth } from '../../redux/actions/operInfo'
import { change_userInfo } from '../../redux/actions/userInfo'
import { change_brokerId, change_brokerType, change_job } from '../../redux/actions/brokerId'
import md5 from 'js-md5'
import { rules } from '../../common'
import chrome from '../../assets/images/chrome.png'
import edge from '../../assets/images/edge.png'
import firefox from '../../assets/images/firefox.png'
import Filter from '../../common/filter'
import safari from '../../assets/images/safari.png'
const { confirm } = Modal
const FormItem = Form.Item

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field])
}

class LoginPage extends React.Component {
  formRef = React.createRef()
  captchaInsRef = React.createRef()
  constructor(props) {
    super(props)
    this.state = {
      loginType: 1, //1验证码
      seconds: 60, //称数初始化
      canGetCode: true,
      loading: false,
      IsTrue: false,
      no: window.sign_requestUrl === 'prd' ? '沪ICP备16012385号-3' : '沪ICP备16012385号-4',
    }
  }

  handleSubmit = e => {
    let arr = ['mobileNo']
    if (this.state.loginType == 1) {
      arr.push('mobileCheckNo')
    } else {
      arr.push('password')
    }
    this.formRef.current
      .validateFields(arr)
      .then(async values => {
        if (this.state.loginType == 1) {
          this.goLogin({
            regChannel: '3',
            loginType: 'checkNo',
            mobileNo: values.mobileNo,
            mobileCheckNo: values.mobileCheckNo,
          })
        } else {
          this.goLogin({
            regChannel: '3',
            loginType: 'password',
            mobileNo: values.mobileNo,
            password: md5(values.password.toUpperCase() + window.md5Key),
          })
        }
      })
      .catch(info => {
        console.log('Validate Failed:', info)
      })
  }

  goLogin = async obj => {
    try {
      this.setState({
        loading: true,
      })
      localStorage.clear()
      sessionStorage.clear()
      const res = await Axios('get', '/login/open/login', obj, 'login')
      if (res.isSuccess === 'T') {
        localStorage.setItem('sessionId', res.sessionId)
        this.GetUserInfo(res)
      } else {
        this.setState({
          loading: false,
        })
        message.error(res.errorMsg)
      }
      this.captchaInsRef.current && this.captchaInsRef.current.refresh()
    } catch (err) {
      this.setState({
        loading: false,
      })
      this.captchaInsRef.current && this.captchaInsRef.current.refresh()
      console.log(err)
    }
  }
  GetUserInfo = async values => {
    try {
      const res = await Axios('get', '/brokeruser/info', {})
      if (res.code == '200') {
        if (res.data.isOver == true) {
          this.showConfirm(res.data.endDate || '')
          localStorage.setItem('sessionId', '')
          this.setState({
            loading: false,
          })
        } else {
          this.props.change_operInfo(values.body)
          this.getUser()
        }
      } else {
        this.setState({
          loading: false,
        })
        message.error(res.message)
      }
    } catch (err) {
      console.log(err)
    }
  }
  getUser = async () => {
    localStorage.removeItem('BrokerId')
    localStorage.removeItem('brokerIds')
    try {
      const res = await Axios('get', '/brokeruser', {})
      this.setState({
        loading: false,
      })
      if (res.code === '200') {
        this.props.change_brokerId(res.data.brokerId)
        this.props.change_userInfo(res.data)

        localStorage.setItem('userName', res.data.userName)
        localStorage.setItem('brokerIds', res.data.id)
        localStorage.setItem('BrokerId', res.data.brokerId)
        this.props.change_brokerType(res.data.brokerType || '')
        this.props.change_job(res.data.job || '')
        // message.success('登录成功');
        this.props.change_operAuth({
          // userRole:'1',
          userRole: res.data.userRole || '',
        })
        this.props.history.push('/workTable')
      } else {
        message.error(res.message)
      }
    } catch (err) {
      this.setState({
        loading: false,
      })
      console.log(err)
    }
  }
  // 获取验证码
  sendCode = () => {
    if (!this.state.canGetCode) {
      return
    }

    this.formRef.current
      .validateFields(['mobileNo'])
      .then(async values => {
        this.setState({ canGetCode: false })
        if (this.captchaInsRef.current) {
          this.captchaInsRef.current.verify()
        } else {
          message.error('云盾初始化失败')
        }
      })
      .catch(info => {
        console.log('Validate Failed:', info)
      })
  }

  //获取验证码调用
  getValidCode = validate => {
    this.formRef.current
      .validateFields(['mobileNo'])
      .then(async values => {
        this.setState({ canGetCode: false })

        try {
          let res = await Axios(
            'get',
            '/login/open/check/sendCheckNo',
            {
              mobileNo: values.mobileNo,
              smsKind: 'checkNo',
              NECaptchaValidate: validate,
            },
            'login'
          )
          if (res.isSuccess === 'T') {
            message.success('发送成功')
            let siv = setInterval(() => {
              this.setState(
                {
                  seconds: this.state.seconds - 1,
                },
                () => {
                  if (this.state.seconds == 0) {
                    clearInterval(siv)
                    this.setState({
                      seconds: 60,
                      canGetCode: true,
                    })
                    this.captchaInsRef.current && this.captchaInsRef.current.refresh()
                  }
                }
              )
            }, 1000)
          } else {
            this.setState({
              canGetCode: true,
            })
            message.error(res.errorMsg)
            this.captchaInsRef.current && this.captchaInsRef.current.refresh()
          }
        } catch (err) {
          console.log('err', err)
          this.setState({
            canGetCode: true,
          })
        }
      })
      .catch(info => {
        console.log('Validate Failed:', info)
      })
  }

  componentDidMount() {
    const _this = this
    // eslint-disable-next-line no-undef
    initNECaptcha(
      {
        captchaId: '225e3eed9e9a4b5aac9bc496b2952625',
        element: '#loginBlock',
        appendTo: '#loginBlock',
        mode: 'bind',
        width: '320px',
        feedbackEnable: false,
        onVerify: (err, data) => {
          if (err) return
          //云盾密码登录或是获取校验码
          data.validate && this.getValidCode(data.validate)
        },
        onClose: () => {
          //
        },
      },
      function onload(instance) {
        _this.captchaInsRef.current = instance
      },
      function onerror(err) {
        console.log('云盾失败=', err)
      }
    )
  }
  showConfirm = value => {
    confirm({
      title: <p style={{ position: 'absolute', marginTop: '-16px' }}>业务到期提醒</p>,
      icon: null,
      width: 450,
      closable: true,
      maskClosable: true,
      content: (
        <div>
          <p style={{ margin: '20px 0' }}>
            尊敬的客户您好，您的业务合作已于&nbsp;{Filter.date(value) || '-'}&nbsp;结束，
            <br /> 如需使用系统，请联系相关招商业务经理为您续约账号权益。
          </p>
          <p style={{ borderTop: '1px dashed #a7aaad', borderBottom: '1px dashed #a7aaad', padding: '12px 0 38px' }}>
            <img style={{ width: '18px' }} src={require('../../assets/images/phone.png')} alt='' /> 若未有线下招商业务经理服务，可咨询： <br />{' '}
            <span
              style={{
                fontSize: '16px',
                fontWeight: 'bold',
              }}
            >
              4000-697-267
            </span>
            （官方热线）
          </p>
        </div>
      ),
      cancelText: '切换账号',
      onOk() {
        console.log('OK')
      },
      onCancel() {
        console.log('Cancel')
      },
    })
  }
  componentWillUnmount() {}
  onChange = e => {
    const reg = new RegExp(/^(?:(?:\+|00)86)?1[2-9]\d{9}$/)
    if (reg.test(e.target.value)) {
      this.setState({
        IsTrue: true,
      })
    } else {
      if (this.state.IsTrue) {
        this.setState({
          IsTrue: false,
        })
      }
    }
  }
  render() {
    return (
      <div id='loginpagewrap'>
        <div className='login_logo'>
          <img src={require('../../assets/images/login_logo.png')} alt='' />
          <span>|</span>

          <span>业务管理系统</span>
        </div>
        <div id='loginWrap'>
          <div className='bg'>
            <h1>赢火虫云法务</h1>
            <p>让企业家拥有更多法律安全感！</p>
          </div>
          <div className='form' id='loginBlock'>
            <Card
              title={
                <p className='tilte'>
                  <span
                    className={this.state.loginType == 1 ? 'tilteSpan' : ''}
                    onClick={() => {
                      this.setState({
                        loginType: 1,
                      })
                    }}
                  >
                    短信登录{' '}
                  </span>{' '}
                  <span>|</span>{' '}
                  <span
                    className={this.state.loginType == 1 ? '' : 'tilteSpan'}
                    onClick={() => {
                      this.setState({
                        loginType: 2,
                      })
                    }}
                  >
                    密码登录
                  </span>
                </p>
              }
              style={{
                width: 440,
                height: 432,
                background: '#FFFFFF',
                borderRadius: 12,
                borderColor: '#F2F2F4FF',
              }}
              hoverable
            >
              <Form onFinish={this.handleSubmit} ref={this.formRef}>
                <FormItem
                  name='mobileNo'
                  rules={[
                    {
                      required: true,
                      message: '请输入电话号码',
                    },
                    {
                      pattern: /^(?:(?:\+|00)86)?1[1-9]\d{9}$/,
                      message: '  ',
                    },
                    rules.mobileNo,
                  ]}
                >
                  <Input onChange={this.onChange} allowClear placeholder='请输入手机号' maxLength={11} />
                </FormItem>
                {this.state.loginType == 1 ? (
                  <FormItem
                    className='FormItem'
                    name='mobileCheckNo'
                    rules={[
                      {
                        required: true,
                        message: '请输入验证码',
                      },
                    ]}
                    style={this.state.loginType == 1 ? {} : { display: 'none' }}
                  >
                    <Input
                      autoComplete='off'
                      size={'large'}
                      placeholder='请输入验证码'
                      maxLength={6}
                      suffix={
                        <div onClick={this.sendCode} className={this.state.IsTrue ? 'sendCode sendCodeBlue' : 'sendCode'}>
                          {this.state.canGetCode ? <>获取验证码</> : <strong className='sendCodegray'>{this.state.seconds + ' s 后重发'}</strong>}
                        </div>
                      }
                    />
                  </FormItem>
                ) : (
                  ''
                )}
                {this.state.loginType != 1 ? (
                  <FormItem
                    style={this.state.loginType != 1 ? {} : { display: 'none' }}
                    name='password'
                    rules={[
                      {
                        required: true,
                        message: '请输入密码',
                      },
                    ]}
                  >
                    <Input style={{ height: '52px' }} autoComplete='off' size={'large'} type='password' placeholder='请输入密码' />
                  </FormItem>
                ) : (
                  ''
                )}

                <Button
                  id='loginBtn'
                  // onClick={this.handleSubmit}
                  // type="primary"
                  htmlType='submit'
                  style={{ borderRadius: '60px' }}
                  loading={this.state.loading}
                >
                  立即登录
                </Button>
              </Form>
            </Card>
          </div>
        </div>
        <div className='bottom'>
          <div className='bottomTop'>
            <div className='helps'>
              <span>为了您良好的使用体验，建议您使用谷歌、火狐、Edge、Safari浏览器</span>
              <img src={chrome} alt='' />
              <img src={firefox} alt='' />
              <img src={edge} alt='' />
              <img src={safari} alt='' />
            </div>
          </div>
          <div className='bottomUnder'>Copyright © 2021 赢火虫信息科技（上海）有限公司 4000-697-267 {this.state.no}</div>
        </div>
      </div>
    )
  }
}

let Login = LoginPage
let LoginM = connect(
  state => {
    return state
  },
  dispatch => ({
    change_operInfo: bindActionCreators(change_operInfo, dispatch),
    change_userInfo: bindActionCreators(change_userInfo, dispatch),
    change_operAuth: bindActionCreators(change_operAuth, dispatch),
    change_brokerId: bindActionCreators(change_brokerId, dispatch),
    change_brokerType: bindActionCreators(change_brokerType, dispatch),
    change_job: bindActionCreators(change_job, dispatch),
  })
)(Login)

export default LoginM
