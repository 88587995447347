import React, { Component } from 'react'
import { Row, Breadcrumb, Descriptions, Button, Form, message, DatePicker, Modal, Input, Select, Col, Popover, Table, Divider, notification, Tag, Spin, Drawer } from 'antd'
import { dynamicType } from '../../common'
import Filter from '../../common/filter'
import { connect } from 'react-redux'
import { Axios } from '../../axios'
import { getDetHtml } from '../../common/opportunityTemplate'
import { popoverTable } from '../../common'
import { FollowConversion } from './Common'
import L from '../../assets/images/LL.png'
import S from '../../assets/images/S.png'
import M from '../../assets/images/M.png'
import XS from '../../assets/images/XS.png'
import { InfoCircleOutlined, RightOutlined, UpOutlined, DownOutlined } from '@ant-design/icons'
import LsLawyer from './component/LsLawyer'
const { Option } = Select
const { RangePicker } = DatePicker
let setPageData = {},
  sendBody = {},
  Timer
let loading_exportOrders = false,
  message_loading = ''

class NewOpportunities extends Component {
  introduceRef = React.createRef()
  constructor(props) {
    super(props)
    this.state = {
      tData: [],
      loading: false,
      analyzeData: {},
      totalNum: 0,
      visible: false,
      companyConciseVO: {},
      bgDet: {},
      modalVisible: false,
      ggDet: '',
      currentPage: window.routerCache.NewOpportunities.currentPage || 1,
      jHHtml: '',
      jHSpinLoading: false,
      selectData: {},
      qzkhLoading: false,
      resCaseData: {},
      showIntroEllipsis: false,
      showMore: false,
    }
    sendBody = window.routerCache.NewOpportunities.sendBody || {}
    setPageData = {}
  }

  // 获取表格数据
  refreshTableByData = (data, totalNum, currentPage) => {
    this.setState({ tData: data, totalNum: totalNum, currentPage: currentPage }, this.polling)
  }
  // 获取表格数据
  refreshTable = async () => {
    try {
      this.setState({
        loading: true,
      })
      let send = sendBody
      // send.workType = window.routerCache.NewOpportunities.send_workType || '';
      send.pageNum = this.state.currentPage + ''
      send.pageSize = '10'
      const brokerList1 = JSON.parse(localStorage.getItem('brokerList')) || []
      brokerList1.filter(v => {
        if (v.brokerId == window.globalData.headDeptId) {
          if (v.city == '昆山市' && !v.cityCode) {
            send.provinceCode = '32'
            send.cityCode = '05'
            send.countyCode = '83'
          } else if (v.city == '常熟市' && !v.cityCode) {
            send.provinceCode = '32'
            send.cityCode = '05'
            send.countyCode = '81'
          } else {
            send.provinceCode = v.provinceCode
            send.cityCode = v.cityCode
            send.countyCode = v.countyCode
          }
          if (send.provinceCode == '' || send.provinceCode == 'undefined') {
            delete send.provinceCode
          }
          if (send.cityCode == '' || send.cityCode == 'undefined') {
            delete send.cityCode
          }
          if (send.countyCode == '' || send.countyCode == 'undefined') {
            delete send.countyCode
          }
        }
      })

      let res = await Axios('get', '/casechance/chances/v3', send, 'case-chance')
      this.setState(
        {
          loading: false,
          tData: res.data.dataList,
          totalNum: res.data.totalNum > 10000 ? 10000 : res.data.totalNum,
        },
        this.polling
      )
      setPageData[this.state.currentPage + ''] = res.data.dataList
    } catch (err) {
      // message.error(err);
      this.setState({ loading: false })
    }
  }

  getCompanyDet = async (data, type) => {
    let sendData = {
      companyName: '',
      keyNo: '',
    }
    let sendData2 = {
      companyName: '',
      cid: '',
    }
    if (type == 1) {
      //潜在客户
      sendData.companyName = data.potentialCustomers
      sendData.keyNo = data.potentialCustomersCompanyId

      sendData2.companyName = data.potentialCustomers
      sendData2.cid = data.potentialCustomersCompanyId
    } else if (type == 2) {
      //对方当事人
      sendData.companyName = data.name
      sendData.keyNo = data.litigantId

      sendData2.companyName = data.name
      sendData2.cid = data.litigantId
    }
    try {
      this.setState({
        visible: true,
        analyzeData: '',
        companyConciseVO: {},
        qzkhLoading: true,
        keHuType: type,
      })
      let returnData = await Axios('get', '/eci/v8/detail', sendData, 'fireflyErp') //企业详情
      let resLawyerRate = await Axios('get', '/company/proxyRate', sendData2, 'bigData') //律师代理率
      let resCaseData = await Axios('get', '/company/analysis', sendData2, 'bigData') //案件总数
      if (resLawyerRate.isSuccess == 'T' && resLawyerRate.body) {
        this.setState({
          analyzeData: JSON.parse(resLawyerRate.body),
        })
      }
      if (resCaseData.isSuccess == 'T' && resCaseData.body) {
        this.setState({
          resCaseData: JSON.parse(resCaseData.body),
        })
      }

      if (returnData.isSuccess == 'T') {
        this.setState({
          companyConciseVO: returnData.body.companyConciseVO,
        })
        this.handleWindowResize()
      } else {
        message.error(returnData.errorMsg)
      }
      this.setState({
        detLoading: false,
        qzkhLoading: false,
      })
    } catch (error) {
      this.setState({
        detLoading: false,
        qzkhLoading: false,
      })
      message.error(error.message)
    }
  }

  getCompanyDetInfo = async value => {
    let sendData = {
      collectionName: value.collectionName,
      hbaseRowkey: value.hbaseRowkey,
    }
    this.setState({
      selectData: value,
      modalJhVisible: true,
      jHSpinLoading: true,
      jHHtml: '',
    })
    try {
      let returnData = await Axios('get', '/casechance/chances/v3/detail', sendData, 'case-chance')
      let tempHtml = ''
      if (returnData.code == '200') {
        tempHtml = getDetHtml(value, returnData.data)
      } else {
        message.error(returnData.message)
      }
      this.setState({
        jHHtml: tempHtml,
        detLoading: false,
        jHSpinLoading: false,
      })
    } catch (error) {
      this.setState({
        detLoading: false,
        jHSpinLoading: false,
        jHHtml: '',
      })
      message.error(error.message)
    }
  }

  handleTableChange = async pagination => {
    this.setState({ loading: true })
    if (Object.keys(setPageData).includes(pagination.current + '')) {
      this.setState({
        loading: false,
        tData: setPageData[pagination.current + ''],
        currentPage: parseInt(pagination.current),
      })
    } else {
      try {
        let send = sendBody
        send.pageNum = pagination.current + ''
        send.pageSize = '10'
        let res = await Axios('get', '/casechance/chances/v3', send, 'case-chance')
        this.setState(
          {
            loading: false,
            tData: res.data.dataList,
            totalNum: res.data.totalNum > 10000 ? 10000 : res.data.totalNum,
            currentPage: pagination.current,
          },
          this.polling
        )
        setPageData[pagination.current + ''] = res.data.dataList
      } catch (err) {
        // message.error(err.message);
        this.setState({ loading: false })
      }
    }
  }

  // 组件渲染后获取外界数据(GET)
  componentDidMount() {
    // this.refreshTable();
    // window.winhcFn.changeDept = () => {
    //     this.setState(
    //         {
    //             currentPage: 1,
    //         },
    //         () => {
    //             this.refreshTable();
    //         }
    //     );
    // };
  }

  componentWillUnmount() {
    window.routerCache.NewOpportunities.currentPage = this.state.currentPage
    window.routerCache.NewOpportunities.sendBody = sendBody
    if (Timer) {
      clearTimeout(Timer)
    }
  }

  polling = () => {
    let gonext = false
    this.state.tData.forEach(item => {
      if (item.status == '0') {
        gonext = true
        return
      }
    })
    if (!gonext) {
      return
    }
    if (Timer) {
      clearTimeout(Timer)
    }
    // setTimeout(async () => {
    //     try {
    //         let send = sendBody;
    //         send.pageNum = this.state.currentPage + "";
    //         send.pageSize = "10";
    //         let res = await Axios(
    //             "get",
    //             "/brokeruser/payCompany/list",
    //             send
    //         );
    //         if (this.state.currentPage == sendBody.pageNum) {
    //             this.setState(
    //                 {
    //                     tData: res.data.dataList,
    //                     totalNum: res.data.totalNum,
    //                 },
    //                 this.polling
    //             );
    //             setPageData[this.state.currentPage + ""] =
    //                 res.data.dataList;
    //         }
    //     } catch (err) {
    //         // message.error(err.message);
    //         console.log(err);
    //     }
    // }, 1000);
  }
  exportOrders = async () => {
    if (!loading_exportOrders) {
      loading_exportOrders = true
      message_loading = message.loading('生成中', 0)
      try {
        let res = await Axios('POST', '/chance/export', sendBody)
        console.log(res)
        if (res.code == '200') {
          notification.success({
            message: res.message,
          })
        } else {
          notification.warning({
            message: res.message,
          })
        }
        loading_exportOrders = false
        message_loading()
        message_loading = ''
      } catch (error) {
        console.log(error)
        loading_exportOrders = false
        message_loading()
        message_loading = ''
      }
    }
  }

  handleWindowResize = () => {
    if (this.introduceRef.current) {
      const lineHeight = parseInt(getComputedStyle(this.introduceRef.current).lineHeight)
      const maxHeight = 1 * lineHeight
      if (this.introduceRef.current.clientHeight > maxHeight) {
        this.setState({
          showIntroEllipsis: true,
        })
      } else {
        this.setState({
          showIntroEllipsis: false,
        })
      }
    }
  }

  render() {
    const { companyConciseVO, analyzeData, resCaseData } = this.state
    let columns = [
      {
        title: '潜在客户',
        dataIndex: 'potentialCustomers',
        width: 200,
        render: (text, r) => (
          <>
            <a onClick={() => this.getCompanyDet(r, '1')}>{text}</a>
            <br />
            <FollowConversion record={r}>
              <span style={{ color: '#FE7431', padding: '4px', border: '1px solid #FE7431', borderRadius: '2px', fontSize: '10px', cursor: 'pointer' }}>跟进转化</span>
            </FollowConversion>
          </>
        ),
      },
      {
        title: '联系方式',
        dataIndex: 'potentialCustomersPhones',
        render: text => (
          <Popover
            content={
              <div>
                {text.map(v => (
                  <p>
                    <a>{v}</a>
                  </p>
                ))}
              </div>
            }
          >
            <a>
              {text[0]}（{text.length}）
            </a>
          </Popover>
        ),
      },
      {
        title: '法定代表人',
        width: 90,
        dataIndex: 'legalEntities',
        render: text => (text ? text.toString() : '-'),
      },
      popoverTable(8, {
        title: '地址',
        dataIndex: 'regLocation',
      }),
      {
        title: '标签',
        dataIndex: 'linkman',
        width: 200,
        render(t, r) {
          return (
            <span>
              {r.city ? <Tag> {r.city}</Tag> : ''}
              {r.regCapital ? <Tag>注册资本：{r.regCapital}</Tag> : ''}
              {r.estiblishTime ? <Tag> {r.estiblishTime}</Tag> : ''}
              {r.cateFirst ? <Tag> {r.cateFirst}</Tag> : ''}
              {r.socialSecurityStaffNum ? <Tag> 社保人数：{r.socialSecurityStaffNum}</Tag> : ''}
            </span>
          )
        },
      },
      {
        title: '案源类型',
        dataIndex: 'describe',
        render: (text, r) => <a onClick={() => this.getCompanyDetInfo(r)}>{text}</a>,
      },
      popoverTable(4, {
        title: '线索分类',
        dataIndex: 'type',
      }),
      {
        title: '对方当事人',
        dataIndex: 'oppositeParty',
        render: (text, r) => {
          let html = []
          if (text && text.length > 0) {
            text.map((e, index) => {
              if (e.litigantId) {
                html.push(
                  <a onClick={() => this.getCompanyDet(e, 2)}>
                    {e ? e.name : ''}
                    {text.length == index + 1 ? '' : ':'}
                  </a>
                )
              } else {
                html.push(
                  <span>
                    {e ? e.name : ''}
                    {text.length == index + 1 ? '' : ':'}
                  </span>
                )
              }
            })
          }
          return html
        },
      },
      {
        title: '更新时间',
        dataIndex: 'updateTime',
      },
    ]
    const operInfo = this.props.operInfo
    console.log(operInfo, 'operInfo', this.props.operInfo)
    return (
      <div className='pageContainer'>
        <Row className='headNav'>
          <Col span={24}>
            <Breadcrumb separator='>'>
              <Breadcrumb.Item>营销获客</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col span={24} className='title'>
            <span className='name'>新增机会</span>
          </Col>
        </Row>
        <div className='scrollContainer'>
          <div className='scrollMain'>
            <Search refreshTableByData={this.refreshTableByData} refreshTable={this.refreshTable} />
            <Modal
              title='破产公告详情'
              open={this.state.modalVisible}
              footer={null}
              onCancel={() => {
                this.setState({ modalVisible: false })
              }}
              width='60%'
            >
              <Spin spinning={false}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.state.ggDet,
                  }}
                ></div>
              </Spin>
            </Modal>
            <Drawer
              title={
                <h4>
                  机会详情
                  <span
                    style={{
                      color: '#0CA980',
                      marginLeft: 15,
                      fontSize: 15,
                      cursor: 'pointer',
                    }}
                  >
                    {this.state.selectData.describe}
                    {this.state.selectData.updateTime}
                  </span>
                </h4>
              }
              open={this.state.modalJhVisible}
              footer={null}
              onClose={() => {
                this.setState({ modalJhVisible: false })
              }}
              width='1000px'
            >
              <Spin spinning={this.state.jHSpinLoading}>
                <div style={{ minHeight: 150 }}>{this.state.jHHtml}</div>
              </Spin>
            </Drawer>

            <div style={{ backgroundColor: '#fff' }}>
              <Row type='flex' justify='space-between' align='middle'></Row>
              {operInfo.userRole !== '2' ? (
                <div
                  style={{
                    width: '95%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <span></span>
                  <Button
                    onClick={() => {
                      this.exportOrders()
                    }}
                    style={{ marginBottom: '20px' }}
                  >
                    生成报表
                  </Button>
                </div>
              ) : (
                ''
              )}

              <Table
                scroll={{ x: 1200 }}
                dataSource={this.state.tData}
                columns={columns}
                size='middle'
                pagination={{
                  size: 'large',
                  showQuickJumper: true,
                  pageSize: 10,
                  total: parseInt(this.state.totalNum),
                  current: Number(this.state.currentPage),
                }}
                loading={this.state.loading}
                onChange={this.handleTableChange}
              />
            </div>
          </div>
        </div>
        <Drawer
          title={this.state.keHuType == 1 ? '潜在客户' : '对方当事人'}
          width={'60%'}
          placement='right'
          closable={true}
          onClose={() =>
            this.setState({
              visible: false,
            })
          }
          open={this.state.visible}
        >
          <Spin spinning={this.state.qzkhLoading}>
            <div>
              <h2 style={{ marginBottom: '0px' }}>{companyConciseVO.name || '-'}</h2>
              <div style={{ color: '#8F959E', fontSize: '14px' }}>企业数据最近更新时间：{companyConciseVO.crawledTime}</div>
            </div>
            <div style={{ marginTop: '16px', background: '#F7FAFF', border: '1px solid #E6F0FF', borderRadius: '4px', padding: '16px 12px' }}>
              <div style={{ display: 'flex' }}>
                <span style={{ width: '50px' }}>电话：</span>
                <div style={{ display: 'flex', flexWrap: 'wrap', flex: 1 }}>
                  {companyConciseVO.telInfo
                    ? companyConciseVO.telInfo.map((item, index) => {
                        return (
                          <div key={index}>
                            <span style={{ color: 'rgba(0,0,0,0.90)' }}>{item.tel}</span>
                            {companyConciseVO.telInfo.length - 1 > index && <Divider type='vertical' />}
                          </div>
                        )
                      })
                    : '-'}
                </div>
              </div>
              <div style={{ display: 'flex', marginTop: '8px' }}>
                <span style={{ width: '50px' }}>邮箱：</span>
                <div style={{ display: 'flex', flexWrap: 'wrap', flex: 1 }}>
                  {companyConciseVO.emailList
                    ? companyConciseVO.emailList.map((item, index) => {
                        return (
                          <div key={index}>
                            <span style={{ color: 'rgba(0,0,0,0.90)' }}>{item}</span>
                            {companyConciseVO.emailList.length - 1 > index && <Divider type='vertical' />}
                          </div>
                        )
                      })
                    : '-'}
                </div>
              </div>
            </div>
            <div style={{ marginTop: '16px', background: '#FAFAFA', border: '1px solid #E6E6E6', borderRadius: '4px', padding: '12px 16px' }}>
              <Descriptions labelStyle={{ color: 'rgba(0, 0, 0, 0.60)' }} contentStyle={{ color: 'rgba(0, 0, 0, 0.90)' }} column={2}>
                <Descriptions.Item label={['51', '52', '53', '54'].includes(companyConciseVO.companyType) ? '法定代表人' : ['11', '12'].includes(companyConciseVO.companyType) ? '负责人' : companyConciseVO.legalAppellation}>
                  {companyConciseVO.legalEntityVOList && companyConciseVO.legalEntityVOList.length > 0 ? (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span style={{ marginLeft: '4px' }}>{companyConciseVO.legalEntityVOList[0].name}</span>
                      {companyConciseVO.legalEntityVOList[0].type === '4' && (
                        <Popover content={'原法定代表人可能存在违法行为、不履行职责、损害公司利益等行为。'} trigger='hover'>
                          <InfoCircleOutlined style={{ cursor: 'pointer' }} />
                        </Popover>
                      )}
                      {/* {companyConciseVO.legalEntityVOList[0].id && (
                        <a>
                          关联{companyConciseVO.legalEntityVOList[0].relateCompanyCount}家企业 <RightOutlined className='ml-1 text-[12px]' />
                        </a>
                      )} */}
                    </div>
                  ) : (
                    '-'
                  )}
                </Descriptions.Item>

                <Descriptions.Item label='网址'>
                  {companyConciseVO.officialWebsite ? (
                    <a href={companyConciseVO.officialWebsite.indexOf('http') === -1 ? `https://${companyConciseVO.officialWebsite}` : companyConciseVO.officialWebsite} target='_blank' rel='noreferrer'>
                      {companyConciseVO.officialWebsite}
                    </a>
                  ) : (
                    '暂无'
                  )}
                </Descriptions.Item>
                <Descriptions.Item label='登记状态'>{companyConciseVO.regStatus || '-'}</Descriptions.Item>
                <Descriptions.Item label='成立日期'>{companyConciseVO.startDate || '-'}</Descriptions.Item>
                <Descriptions.Item label='企业类型'>{companyConciseVO.companyOrgType || '-'}</Descriptions.Item>
                <Descriptions.Item label='所属行业'>{companyConciseVO.categoryName ? companyConciseVO.categoryName.cateName || '-' : '-'}</Descriptions.Item>
                <Descriptions.Item
                  label={
                    <span>
                      企业规模
                      <Popover
                        content={<div style={{ maxWidth: '300px', color: 'rgba(0,0,0,0.90)' }}>基于大数据模型，结合不同行业企业的经营数据（其中上市/发债企业以合并报表口径）计算形成的L(大型)、M(中型)、S(小型)和XS(微型)四类企业规模分类体系（部分缺乏判定指标或新成立的企业不纳入计算范畴）。</div>}
                        trigger='hover'
                      >
                        <InfoCircleOutlined style={{ marginLeft: '4px' }} />
                      </Popover>
                    </span>
                  }
                >
                  <span className='flex items-center' style={{ display: 'flex', alignItems: 'center' }}>
                    {companyConciseVO.scale && <img src={`${companyConciseVO.scale === '微型' ? XS : companyConciseVO.scale === '小型' ? S : companyConciseVO.scale === '中型' ? M : companyConciseVO.scale === '大型' ? L : ''}`} alt='' style={{ width: '16px', height: '16px', marginRight: '4px' }} />}
                    {companyConciseVO.scale || '-'}
                  </span>
                </Descriptions.Item>
                <Descriptions.Item label='统一社会信用代码'>{companyConciseVO.creditCode || '-'}</Descriptions.Item>
                <Descriptions.Item label='注册资本'>{companyConciseVO.regCapital || '-'}</Descriptions.Item>

                {/* <Descriptions.Item label='实缴资本'>
                {Number(companyConciseVO.actualCapitalAmount)
                  ? Number(companyConciseVO.actualCapitalAmount) / 1000000 + '万' + companyConciseVO.actualCapitalCurrency
                  : '-'}
              </Descriptions.Item> */}

                <Descriptions.Item label='参保人数'>{Number(companyConciseVO.socialSecurityStaffNum) ? companyConciseVO.socialSecurityStaffNum + '人' : '暂无'}</Descriptions.Item>
                <Descriptions.Item label={<span>注册地址</span>} span={2}>
                  <span>{companyConciseVO.regLocation || '-'}</span>
                  {companyConciseVO.regLocation && (
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <a
                      style={{ marginLeft: '4px' }}
                      onClick={() => {
                        window.open(`http://api.map.baidu.com/geocoder?address=${companyConciseVO.regLocation}&output=html&src=webapp.baidu.openAPIdemo`)
                      }}
                    >
                      地图
                    </a>
                  )}
                </Descriptions.Item>
                <Descriptions.Item label='简介' span={2}>
                  <div style={{ display: 'flex' }}>
                    <div style={{ flex: 1 }} className={`${this.state.showIntroEllipsis && !this.state.showMore ? 'line-clamp' : ''}`} ref={this.introduceRef}>
                      {companyConciseVO.description}
                    </div>
                    {this.state.showIntroEllipsis && (
                      <div
                        style={{ width: '60px', cursor: 'pointer', textAlign: 'right' }}
                        onClick={() => {
                          this.setState({ showMore: !this.state.showMore })
                        }}
                      >
                        {this.state.showMore ? (
                          <a>
                            收起
                            <UpOutlined style={{ marginLeft: '5px' }} />
                          </a>
                        ) : (
                          <a>
                            更多
                            <DownOutlined style={{ marginLeft: '5px' }} />
                          </a>
                        )}
                      </div>
                    )}
                  </div>
                </Descriptions.Item>
              </Descriptions>
            </div>
            <div style={{ marginTop: '16px', background: '#fff', borderRadius: '4px', padding: '12px 16px', border: '1px solid #E7E7E7' }}>
              <div>
                <span style={{ color: 'rgba(0,0,0,0.60)' }}>案件总数：</span>
                <span style={{ color: 'rgba(0,0,0,0.90)' }}>{resCaseData.caseNum || 0}</span>
              </div>
              <div style={{ marginTop: '8px' }}>
                <span style={{ color: 'rgba(0,0,0,0.60)' }}>律师代理率：</span>
                <span style={{ color: 'rgba(0,0,0,0.90)' }}>{analyzeData.lawfirmProxyPercent || '0%'}</span>
              </div>
              <div style={{ marginTop: '8px' }}>
                <div style={{ color: 'rgba(0,0,0,0.60)' }}>历史代理律师：</div>
                {companyConciseVO.keyNo && (
                  <div style={{ marginTop: '4px', background: '#FAFAFA', borderRadius: '4px', padding: '12px' }}>
                    <LsLawyer id={companyConciseVO.keyNo} />
                  </div>
                )}
              </div>
            </div>
          </Spin>
        </Drawer>
        <style>{`
          .line-clamp{
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical
          }
            .ant-descriptions-item-label{
              width:90px;
            }
          `}</style>
      </div>
    )
  }
}

export default connect(
  state => {
    return state
  },
  dispatch => ({})
)(NewOpportunities)

class SearchM extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)
    this.state = {
      // sDate: "",
      // eDate: "",
      province: '',
      city: '',
      county: '',
      qingkong: false,
      dynamicTypeList: [],
      industryListFirst: [],
      industryListSecond: [],
      firstIndustryValue: undefined,
      secondIndustryValue: undefined,
      brokerList: [],
      provinceCode: '',
    }
  }

  // 查询
  query = async () => {
    setPageData = {}
    let _this = this
    sendBody = this.formRef.current.getFieldsValue()
    if (sendBody.pushDate) {
      sendBody.dateFrom = Filter.timetrans(sendBody.pushDate[0], 'day')
      sendBody.dateTo = Filter.timetrans(sendBody.pushDate[1], 'day')
      delete sendBody.pushDate
    }
    if (sendBody.code) {
      sendBody.firstCode = sendBody.code.split('_')[0]
      sendBody.secondCode = sendBody.code.split('_')[1]
      delete sendBody.code
    }
    // _this.props.refreshTableByData([], 1, 1);
    sendBody.pageNum = '1'
    sendBody.pageSize = '10'

    if (sendBody.total) {
      sendBody.provinceCode = sendBody.total.split('~')[0] || undefined
      sendBody.cityCode = sendBody.total.split('~')[1] || undefined
      sendBody.countyCode = sendBody.total.split('~')[2] || undefined
      if (sendBody.provinceCode == '' || sendBody.provinceCode == 'undefined') {
        delete sendBody.provinceCode
      }
      if (sendBody.cityCode == '' || sendBody.cityCode == 'undefined') {
        delete sendBody.cityCode
      }
      if (sendBody.countyCode == '' || sendBody.countyCode == 'undefined') {
        delete sendBody.countyCode
      }
      delete sendBody.total
    }

    if (sendBody.Num) {
      sendBody.maxSocialSecurityStaffNum = sendBody.Num.split('-')[1]
      sendBody.minSocialSecurityStaffNum = sendBody.Num.split('-')[0]
      delete sendBody.Num
    }

    //企业行业
    if (this.state.firstIndustryValue) {
      sendBody.cateFirst = this.state.firstIndustryValue
      if (this.state.secondIndustryValue) {
        sendBody.cateSecond = this.state.secondIndustryValue
      }
    }
    console.log(sendBody)
    try {
      let res = await Axios('get', '/casechance/chances/v3', sendBody, 'case-chance')

      _this.props.refreshTableByData(res.data.dataList, res.data.totalNum > 10000 ? 10000 : res.data.totalNum, 1)
      setPageData['1'] = res.data.dataList
    } catch (err) {
      // message.error(err.message);
    }
  }
  clearOptions = async () => {
    this.setState(
      {
        province: '',
        city: '',
        county: '',
        qingkong: true,
        firstIndustryValue: undefined,
        secondIndustryValue: undefined,
      },
      () => {
        this.setState({
          qingkong: false,
        })
        this.formRef.current.resetFields()
      }
    )

    sendBody = {}
    setPageData = {}
    sendBody.pageNum = '1'
    sendBody.pageSize = '10'
    // sendBody.workType = window.routerCache.NewOpportunities.send_workType || '';
    const brokerList1 = JSON.parse(localStorage.getItem('brokerList')) || []
    brokerList1.filter(v => {
      if (v.brokerId == window.globalData.headDeptId) {
        if (v.city == '昆山市' && !v.cityCode) {
          sendBody.provinceCode = '32'
          sendBody.cityCode = '05'
          sendBody.countyCode = '83'
        } else if (v.city == '常熟市' && !v.cityCode) {
          sendBody.provinceCode = '32'
          sendBody.cityCode = '05'
          sendBody.countyCode = '81'
        } else {
          sendBody.provinceCode = v.provinceCode
          sendBody.cityCode = v.cityCode
          sendBody.countyCode = v.countyCode
        }
        if (sendBody.provinceCode == '' || sendBody.provinceCode == 'undefined') {
          delete sendBody.provinceCode
        }
        if (sendBody.cityCode == '' || sendBody.cityCode == 'undefined') {
          delete sendBody.cityCode
        }
        if (sendBody.countyCode == '' || sendBody.countyCode == 'undefined') {
          delete sendBody.countyCode
        }
      }
    })

    this.props.refreshTableByData([], 1, 1)
    try {
      let res = await Axios('get', '/casechance/chances/v3', sendBody, 'case-chance')

      this.props.refreshTableByData(res.data.dataList, res.data.totalNum > 10000 ? 10000 : res.data.totalNum, 1)
      setPageData['1'] = res.data.dataList
    } catch (err) {}
  }
  // 选择日期范围

  // 组件渲染后获取外界数据(GET)
  componentDidMount() {
    let dynamicTypeList = []
    for (let val in dynamicType) {
      dynamicTypeList.push({ name: dynamicType[val], val: val })
    }
    this.setState(
      {
        dynamicTypeList,
      },
      () => {
        this.getEnterpriseIndustryData()
      }
    )
    let operInfo = JSON.parse(localStorage.getItem('legal_operInfo')).userId
    this.groupData(operInfo)
    this.setState(
      {
        qingkong: true,
      },
      () => {
        this.setState({
          qingkong: false,
        })
      }
    )
  }

  groupData = async userInfo => {
    try {
      let returnData = await Axios('get', '/brokeruser/identityList', { userInfo: userInfo, withBroker: true }, '')

      if (returnData.code == '200') {
        let { userInfo = {} } = this.props
        // console.log("userInfo=",userInfo);
        // console.log("returnData=",returnData);
        // 特殊处理
        if (userInfo.brokerName === '武汉百捷智创企业服务有限公司' && returnData.data.length > 0) {
          let tempString = JSON.stringify(returnData.data[0])
          let tempData = JSON.parse(tempString)
          tempData.provinceCode = '42'
          tempData.cityCode = '06'
          tempData.districtCode = '00'
          tempData.province = '湖北'
          tempData.city = '襄阳市'
          //此处brokerId只做显示用，不是真实id
          tempData.brokerId = tempData.brokerId + 1
          returnData.data.push(tempData)
        }
        localStorage.setItem('brokerList', JSON.stringify(returnData.data))

        this.props.refreshTable()
        this.setState(
          {
            brokerList: returnData.data,
          },
          () => {
            this.state.brokerList.filter(v => {
              if (v.brokerId == window.globalData.headDeptId) {
                let str = ''
                if (v.city == '昆山市' && !v.cityCode) {
                  str = '32~05~83'
                } else if (v.city == '常熟市' && !v.cityCode) {
                  str = '32~05~81'
                } else {
                  str = v.provinceCode + '~' + v.cityCode + '~' + v.countyCode
                }
                this.setState(
                  {
                    provinceCode: str,
                  },
                  () => {
                    this.formRef.current.setFieldsValue({ total: this.state.provinceCode })
                  }
                )
              }
            })
          }
        )
      }
    } catch (error) {
      console.log(error)
    }
  }
  //获取企业行业数据
  getEnterpriseIndustryData = async (level = 0, name = '') => {
    let send = {
      name: name,
      level: level,
    }
    try {
      let returnData = await Axios('get', '/basicinfo/industrylist', send, 'bigData')
      console.log('企业行业一级数据', returnData)
      let tempList = []
      if (returnData.length > 0) {
        returnData.forEach((e, index) => {
          tempList.push(
            <Option key={e.industryName + '_' + e.levelNum} value={e.industryName}>
              {e.industryName}
            </Option>
          )
        })
        if (level == 0) {
          this.setState({
            industryListFirst: tempList,
          })
        } else if (level == 1) {
          this.setState({
            industryListSecond: tempList,
          })
        } else if (level == 2) {
          // this.setState({
          //     industryListThird:tempList
          // })
        }
      }
    } catch (error) {
      console.log(error)
    }
  }
  industryList = (type, e) => {
    console.log(type, e)
    if (type === 1) {
      this.setState(
        {
          firstIndustryValue: e,
        },
        () => {
          //获取第二级列表数据
          if (!e) {
            return
          }
          this.getEnterpriseIndustryData(1, e)
        }
      )
    } else if (type === 2) {
      this.setState(
        {
          secondIndustryValue: e,
        },
        () => {
          //获取第三级列表数据
          if (!e) {
            return
          }
          this.getEnterpriseIndustryData(2, e)
        }
      )
    } else if (type === 3) {
      // this.setState({
      //     thirdIndustryValue:e
      // })
    }
  }

  render() {
    /*控制查询按钮状态*/
    const { dynamicTypeList, brokerList, provinceCode } = this.state

    return (
      <div className='search_like_antD' style={{ marginTop: '-8px' }}>
        <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} ref={this.formRef}>
          <Row type='flex' align='middle'>
            <Col span='8'>
              <Form.Item label='企业地区' style={{ width: '100%' }} name='total' initialValue={provinceCode}>
                <Select placeholder='请选择' style={{ width: '80%' }}>
                  {brokerList.map(v => {
                    if (v.city == '昆山市' && !v.cityCode) {
                      return (
                        <Option key={'32~05~83'}>
                          {v.province || ''}
                          {v.city || ''} {v.county || ''}{' '}
                        </Option>
                      )
                    } else if (v.city == '常熟市' && !v.cityCode) {
                      return (
                        <Option key={'32~05~81'}>
                          {' '}
                          {v.province || ''} {v.city || ''} {v.county || ''}{' '}
                        </Option>
                      )
                    } else {
                      return (
                        <Option key={v.provinceCode + '~' + v.cityCode + '~' + v.countyCode}>
                          {v.province || ''}
                          {v.city || ''} {v.county || ''}
                        </Option>
                      )
                    }
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span='8'>
              <Form.Item label='更新时间' style={{ width: '100%' }} name='pushDate' initialValue={sendBody.pushDate}>
                <RangePicker disabledDate={this.disabledDate} style={{ width: '80%' }} allowClear />
              </Form.Item>
            </Col>
            <Col span='8'>
              <Form.Item label='线索分类' style={{ width: '100%' }} name='code' initialValue={sendBody.firstCode ? sendBody.firstCode + '_' + sendBody.secondCode : undefined}>
                <Select placeholder='请选择' style={{ width: '80%' }} allowClear>
                  <Option key='11_01'>开庭公告</Option>
                  <Option key='11_02'>立案信息</Option>
                  <Option key='11_03'>送达公告</Option>
                  <Option key='11_04'>法院公告</Option>
                  <Option key='12_01'>财产线索/利好消息</Option>
                  <Option key='12_02'>一审胜诉文书</Option>
                  <Option key='12_03'>终本案件</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row type='flex' align='middle'>
            <Col span='8'>
              <Form.Item label='对方当事人' style={{ width: '100%' }} name='oppositePartyName' initialValue={sendBody.oppositePartyName}>
                <Input placeholder='请输入' type='text' style={{ width: '80%' }} />
              </Form.Item>
            </Col>{' '}
            <Col span='8'></Col>
            <Col span={8}>
              <Row type='flex' justify='end' align='middle' style={{ width: '85%', height: '30px' }}>
                <Form.Item style={{ marginRight: '16px' }}>
                  <Button type='primary' onClick={this.query}>
                    查询
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button onClick={this.clearOptions}>重置</Button>
                </Form.Item>
              </Row>
            </Col>
          </Row>
          <div>
            <Row
              type='flex'
              justify='space-between'
              align='middle'
              style={
                window.routerCache.NewOpportunities.workTypeStr
                  ? {
                      paddingBottom: '10px',
                    }
                  : { paddingBottom: '10px' }
              }
            ></Row>
          </div>
        </Form>
        <style>{`
                .search_like_antD .ant-form ant-form-inline .ant-row-flex .ant-row-flex-space-between .ant-row-flex-middle{
                    padding: 0px !important;
                }
                .bgDet{
                    color:#000;
                    font-size: 14px;
                    margin-top: 10px;
                    display:block;
                     border: 1px solid #E8E8E8;
                }
                .bgDet .row{
                    border-bottom: 1px solid #E8E8E8;
                    align-items:stretch;
                }
                .bgDet .row:last-child{
                    border-bottom: none;
                   
                }
                .bgDet .row .colType .bt{
                    color: #999999;
                    flex:none;
                    border-right: 1px solid #E8E8E8;
                    width:150px;
                    background:#FAFAFA;
                }
                .colType{
                   border-right: 1px solid #E8E8E8;
                  
                }
                .colType:last-child{
                   border-right: none;
                }
                .colType span{
                    flex:1;
                    word-break: break-word;
                    padding: 15px 10px;
                }
                .bgDet .ant-col{
                    display:flex;  
                }
                .qzkhInfo{
                    color:#222;
                    font-size:15px;
                    line-height:40px;
                }
                .qzkhInfo .num{
                    font-size:18px;
                    font-weight:bold;l
                }
                .qzkhInfo .gray{
                    color:#999999
                }
                `}</style>
      </div>
    )
  }
}

let Search = connect(
  state => {
    return state
  },
  dispatch => ({})
)(SearchM)
