import { usePagination } from 'ahooks'
import { Avatar, Drawer, Pagination, Empty } from 'antd'
import React, { ReactElement } from 'react'
import { Axios } from '../../../axios'

async function getList(paginationInfo, id) {
  const params = {
    companyId: id,
    pageNum: paginationInfo.current,
    pageSize: paginationInfo.pageSize,
  }
  const res = await Axios('get', '/lawyer/v2/aggcompany/lawyer/page', params, 'lawyer-workbench')

  return {
    total: Number(res.totalNum) || 0,
    list: res.body || [],
  }
}

const LsLawyer = ({ id }) => {
  const { data, pagination, refresh, loading } = usePagination(
    ({ current, pageSize }) => {
      return getList(
        {
          current,
          pageSize,
        },
        id
      )
    },
    { defaultCurrent: 1, refreshDeps: [id] }
  )
  return (
    <div>
      {data && (data.list || []).length > 0 ? (
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {data.list.map(item => (
            <div key={item.id} style={{ display: 'flex', alignItems: 'center', marginBottom: '28px', width: '50%' }}>
              <Avatar size={40} shape='square' src={item.lawyerImg} style={{ background: '#E7F1FF', color: '#1775FF' }}>
                {item.lawyerName.substring(0, 1)}
              </Avatar>
              <div style={{ marginLeft: '8px' }}>
                <div>{item.lawyerName}</div>
                <div style={{ color: '#616161' }}>{item.lawfirmName}</div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}

      {data && (data.list || []).length > 0 && (
        <Pagination
          current={pagination.current}
          pageSize={pagination.pageSize}
          total={data.total}
          onChange={pagination.onChange}
          // onShowSizeChange={pagination.onChange}
          showTotal={total => '共 ' + total + ' 条记录 '}
          style={{ paddingTop: 26, textAlign: 'right' }}
        />
      )}
    </div>
  )
}

export default LsLawyer
